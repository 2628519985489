var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"jornada-list",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.notifications),function(notification){return _c('tr',{key:notification.id,staticClass:"list-row"},[_c('td',{class:_vm.activeIfNotRead(notification),on:{"click":function($event){return _vm.redirectToWorkday(
              notification.id,
              notification.workday_id,
              notification.user_id
            )}}},[_vm._v(" "+_vm._s(notification.title)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.redirectToWorkday(
              notification.id,
              notification.workday_id,
              notification.user_id
            )}}},[_vm._v(" "+_vm._s(_vm.formatDateISO(notification.workday_created_at))+" ")]),(notification.is_read)?_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.redirectToWorkday(
              notification.id,
              notification.workday_id,
              notification.user_id
            )}}},[_vm._v(" "+_vm._s(_vm.$t(("components.lists.notifications." + (!!notification.read_at ? 'seen_in' : 'see')), { date: _vm.formatDateISO(notification.read_at) } ))+" ")]):_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"button-active",on:{"click":function($event){return _vm.markAsRead(notification.id)}}},[_vm._v(" "+_vm._s(_vm.$t('components.lists.notifications.seen'))+" ")])])])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }