<template>
  <div class="notifications">
    <div v-if="hasNotifications">
      <notifications-header @readAllNotifications="readAllNotifications" />
      <notifications-list
        :notifications="notifications"
        @markAsRead="markAsRead"
        @showWorkdayDetails="showWorkdayDetails"
      />
      <v-row
        justify="end"
      >
        <pagination
          v-if="showPagination"
          :pagination-settings="getNotifications.pagination_settings"
          :page="getPage"
          @changed="changePage"
        />
      </v-row>
    </div>
    <placeholder
      v-else
      message="Nenhuma notificação"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NotificationsList from '@/components/lists/NotificationsList.vue';
import NotificationsHeader from '@/components/headers/NotificationsHeader.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import Placeholder from '@/components/lists/Placeholder.vue';

export default {
  name: 'Notifications',
  components: {
    NotificationsList,
    NotificationsHeader,
    Pagination,
    Placeholder,
  },
  computed: {
    ...mapGetters([
      'getCurrentBreadcrumbs',
      'getNotifications',
      'getUnreadNotificationMark',
      'getPage',
    ]),
    notifications() {
      return this.getNotifications.data;
    },

    hasNotifications() {
      return this.notifications && this.notifications.length > 0;
    },

    showPagination() {
      if (this.notifications && this.notifications.length !== 0) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.fetchNotifications({ page: 1 });
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.notification'),
        disabled: true,
      },
    ]);
  },
  methods: {
    ...mapActions([
      'initCurrentBreadcrumbs',
      'fetchNotifications',
      'markNotificationAsRead',
      'markAllNotificationsAsRead',
      'updatePage',
    ]),
    changePage() {
      this.fetchNotifications({ page: this.getPage });
    },
    markAsRead(notificationId) {
      this.markNotificationAsRead({ notification_id: notificationId });
    },
    readAllNotifications() {
      this.markAllNotificationsAsRead();
    },
    showWorkdayDetails(notificationId, workdayId, userId) {
      this.markNotificationAsRead({ notification_id: notificationId });
      const routeData = this.$router.resolve({ name: 'workdayShow', params: { workdayId, userId } });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
