<template>
  <div class="header-frame d-inline-flex justify-end">
    <v-btn
      large
      color="accent"
      class="edit-button"
      @click="readAllNotifications"
    >
      {{ $t('components.lists.notifications.all_seen') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NotificationsHeader',
  methods: {
    readAllNotifications() {
      this.$emit('readAllNotifications');
    },
  },
};
</script>

<style lang="scss" scoped>
.header-frame {
  width: 100%;
  height: 40px;

  .edit-button {
    font-family: 'Fira-Sans-Bold';
    width: auto;
  }
}
</style>
