<template>
  <div>
    <v-simple-table
      class="jornada-list"
    >
      <template v-slot:default>
        <tbody>
          <tr
            v-for="notification in notifications"
            :key="notification.id"
            class="list-row"
          >
            <td
              :class="activeIfNotRead(notification)"
              @click="redirectToWorkday(
                notification.id,
                notification.workday_id,
                notification.user_id
              )"
            >
              {{ notification.title }}
            </td>
            <td
              class="text-center"
              @click="redirectToWorkday(
                notification.id,
                notification.workday_id,
                notification.user_id
              )"
            >
              {{ formatDateISO(notification.workday_created_at) }}
            </td>
            <td
              v-if="notification.is_read"
              class="text-center"
              @click="redirectToWorkday(
                notification.id,
                notification.workday_id,
                notification.user_id
              )"
            >
              {{
                $t(`components.lists.notifications.${!!notification.read_at ? 'seen_in' : 'see'}`,
                   { date: formatDateISO(notification.read_at) } )
              }}
            </td>
            <td
              v-else
              class="text-center"
            >
              <button
                class="button-active"
                @click="markAsRead(notification.id)"
              >
                {{ $t('components.lists.notifications.seen') }}
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'NotificationsList',
  mixins: [formats],
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    activeIfNotRead(notification) {
      if (notification.is_read) { return ''; }

      return 'is_active';
    },
    redirectToWorkday(notificationId, workdayId, userId) {
      this.$emit('showWorkdayDetails', notificationId, workdayId, userId);
    },
    markAsRead(notificationId) {
      this.$emit('markAsRead', notificationId);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table.jornada-list {
  background-color: $color_background;
  font-family: 'Acumin-Pro-Regular';
  color: $color_list_body;
  padding: 40px 0;

  .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 400;
    color: $color_list_header;
  }

  .list-row {
    padding: 24px 16px;
    height: 64px;
    cursor: pointer;

    .is_active {
      font-family: 'Fira-Sans-Bold';
    }

    .button-active {
      font-family: 'Fira-Sans-Bold';
      padding: 10px;
      border: none;
      border-radius: 8px;
      transition-duration: 200ms;
      color: $color_primary;

      &:hover {
        transition-duration: 200ms;
        background-color: $color_hover_link;
      }
    }
  }
}
</style>
